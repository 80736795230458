//Adaptacion del panel de controla Redwood
body.cp-page--redwood {
  //variables, se cargan solamente para este archivo, no a nivel general
  $themecolor: #333; //color principal
  $themecolor-dark: #000000;
  $themecolor-red: #c62a2a;
  $themecolor-red-white: #E68289;
  $topbar: $themecolor-red;

  $themecolor-alt: #474747; //Sidebar

  $primary: $themecolor;

  $gray-80: #333;

  a {
    color: $themecolor;
  }
  .login-logo {
    width: 151px;
    height: 109px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center top;
    margin: 0 auto;
    @media (min-width: 360px) {
      width: 220px;
      height: 159px;
    }
  }

  .topbar {
    background: $topbar !important;
  }


  .text-themecolor,
  .text-info,
  .text-primary {
    color: $themecolor !important;
  }


  .bg-info{
    background-color:$themecolor !important;
  }

  .btn-info,
  .btn-info.disabled,
  .btn-primary,
  .btn-primary.disabled {
    color: white;
    background: $themecolor;
    border: 1px solid $themecolor;
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;
    &:hover {
      background: $themecolor;
      box-shadow: $default-shadow-hover;
    }
    &.active, &:focus {
      background: $themecolor;
      box-shadow: $default-shadow-hover;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle,
  .btn-info:not(:disabled):not(.disabled).active,
  .btn-info:not(:disabled):not(.disabled):active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: $themecolor;
    border-color: $themecolor;
  }

  .btn-outline-info,
  .btn-outline-primary {
    color: $themecolor;
    background-color: $white;
    border-color: $themecolor;
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
      background: $themecolor;
      box-shadow: $default-shadow-hover;
      color: $white;
      border-color: $themecolor;
    }
    &.active, &:focus {
      box-shadow: $default-shadow-hover;
    }
  }

  .login-register {
    .badge.badge-secondary {
      margin-left: 5px;
    }
    input.form-control {
      padding-left: 10px;
    }
  }

  //sidebar
  .sidebar-nav > ul > li.active > a, .sidebar-nav > ul > li.active:hover > a {
    color: #ffffff;
    background: $gray-80;
  }
  .sidebar-nav ul li a:hover {
    color: $themecolor;
  }
  .sidebar-nav > ul > li > a.active i {
    color: white;
  }
  .sidebar-nav > ul > li > a:hover i {
    color: $themecolor;
  }
  .sidebar-nav > ul > li.active > a:hover i {
    color: white;
  }

  @media (min-width: 768px) {
    &.mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
      width: 260px;
      background: $themecolor;
      color: #fff;
      border-color: $themecolor;
    }
  }

  //tabs de la ficha de activo
  .c-tabs-activo{
    background: $themecolor-red;
    background: -webkit-gradient(left top,left bottom,color-stop(0,$themecolor-red),color-stop(100%,$themecolor-red-white));
    background: -webkit-gradient(linear,left top,left bottom,from($themecolor-red),to($themecolor-red-white ));
    background: linear-gradient(to bottom,$themecolor-red 0,$themecolor-red-white  100%);
  }
  .c-tabs-activo a.nav-link.active, .c-tabs-activo a.nav-link:hover { color: $themecolor-red!important;}
  .c-tabs-activo:after {
    @media (min-width: 1200px) {
      content: "";
      background: url(/img/cp/tabs/city_right_black.png) no-repeat;
    }
  }
  .c-tabs-activo__inner:before{
    content:"";
    background:url(/img/cp/tabs/city_left_black.png) no-repeat;
  }
  .c-tabs-activo a.nav-link.active {
    color: $themecolor !important;
  }

  /*icon asset page*/
  .nav-link.active, .nav-link:hover {
    /*icon tab home*/
    .c-tabs-activo__icon-home:before{
      background:url(/img/cp/icons/home_black.svg) no-repeat !important;
    }

    /*icon tab documents*/
    .c-tabs-activo__icon-documents:before{
      background:url(/img/cp/icons/documents_black.svg) no-repeat !important;
    }

    /*icon tab data*/
    .c-tabs-activo__icon-data:before{
      background:url(/img/cp/icons/data_black.svg) no-repeat !important;
    }

    /*icon tab keys*/
    .c-tabs-activo__icon-keys:before{
      background:url(/img/cp/icons/keys_black.svg) no-repeat !important;
    }

    /*icon tab people*/
    .c-tabs-activo__icon-people:before{
      background:url(/img/cp/icons/people_black.svg) no-repeat !important;
    }

    /*icon tab plano*/
    .c-tabs-activo__icon-plano:before{
      background:url(/img/cp/icons/plano_black.svg) no-repeat !important;
    }

    /*icon tab pomo*/
    .c-tabs-activo__icon-pomo:before{
      background:url(/img/cp/icons/pomo_black.svg) no-repeat !important;
    }

    /*icon tab sensors*/
    .c-tabs-activo__icon-sensores:before{
      background:url(/img/cp/icons/sensores_black.svg) no-repeat !important;
    }

    /*icon tab report*/
    .c-tabs-activo__icon-report:before{
      background:url(/img/cp/icons/report_black.svg) no-repeat !important;
    }

    /*icon tab report*/
    .c-tabs-activo__icon-agreement:before{
      background:url(/img/cp/icons/agreement_black.svg) no-repeat !important;
    }
    /*icon tab extended information*/
    .c-tabs-activo__icon-home-moreinfo:before{
      background:url(/img/cp/icons/home__moreinfo_black.svg) no-repeat !important;
    }
  }

  /* progressbar*/
  .text-info.cp-key-chain-request__progress-icon{
    color:$themecolor !important;
  }
  .progress-bar.bg-info.active{
    background-color:$themecolor !important;
  }

}//close body.cp-page-hecate
