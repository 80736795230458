/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import '../../variable';
@import '../../app';
@import '../../pages';
@import '../../sidebar';
@import '../../icons/font-awesome/css/fontawesome-all.css';
@import '../../icons/simple-line-icons/css/simple-line-icons.css';
@import '../../icons/weather-icons/css/weather-icons.min.css';
@import '../../icons/linea-icons/linea.css';
@import '../../icons/themify-icons/themify-icons.css';
@import '../../icons/flag-icon-css/flag-icon.min.css';
@import "../../icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import '../../widgets';
@import '../../grid';
@import '../../responsive';
@import '../../material';
@import '../../spinners.css';
@import '../../animate.css';

@import '../../adaptation-hecate'; //importante
@import '../../adaptation-neinor'; //importante
@import '../../adaptation-solvia'; //importante
@import '../../adaptation-redwood'; //importante
@import '../../adaptation-altamira'; //importante
@import '../../adaptation-ralset'; //importante
@import '../../adaptation-hipoges'; //importante
@import '../../adaptation-cajamar'; //importante
