/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

$bodyfont:'Poppins', sans-serif;
$headingfont:'Poppins', sans-serif; 
$main-border:#d7dfe3;


/*Theme Colors*/
$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt:#f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4 ;

$light-text: #a6b7bf;
$themecolor: #009efb;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
/*$primary: #7460ee;*/
$primary: #009efb;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;
$third: #39ce66;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4; 



$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
/*$primary-dark: #6352ce;*/
$primary-dark: #0278bd;
$third-dark: $third;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);

$info-shadow: 0 2px 2px 0 rgba(66,165,245,.14), 0 3px 1px -2px rgba(66,165,245,.2), 0 1px 5px 0 rgba(66,165,245,.12);
$info-shadow-hover:0 14px 26px -12px rgba(23,105,255,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(23,105,255,.2);

$primary-shadow: 0 2px 2px 0 rgba(66,165,245,.14), 0 3px 1px -2px rgba(66,165,245,.2), 0 1px 5px 0 rgba(66,165,245,.12);
$primary-shadow-hover:0 14px 26px -12px rgba(23,105,255,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(23,105,255,.2);

$warning-shadow:0 2px 2px 0 rgba(248,194,0,.14), 0 3px 1px -2px rgba(248,194,0,.2), 0 1px 5px 0 rgba(248,194,0,.12);
$warning-shadow-hover:0 14px 26px -12px rgba(248,194,0,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(248,194,0,.2);

$danger-shadow:0 2px 2px 0 rgba(239,83,80,.14), 0 3px 1px -2px rgba(239,83,80,.2), 0 1px 5px 0 rgba(239,83,80,.12);
$danger-shadow-hover:0 14px 26px -12px rgba(239,83,80,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(239,83,80,.2);

$success-shadow:0 2px 2px 0 rgba(40,190,189,.14), 0 3px 1px -2px rgba(40,190,189,.2), 0 1px 5px 0 rgba(40,190,189,.12);
$success-shadow-hover:0 14px 26px -12px rgba(40,190,189,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(40,190,189,.2);
    
/*$primary-shadow:0 2px 2px 0 rgba(116,96,238,.14), 0 3px 1px -2px rgba(116,96,238,.2), 0 1px 5px 0 rgba(116,96,238,.12);
$primary-shadow-hover:0 14px 26px -12px rgba(116,96,238,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(116,96,238,.2);*/

$third-shadow:0 2px 2px 0 rgba(239,83,80,.14), 0 3px 1px -2px rgba(239,83,80,.2), 0 1px 5px 0 rgba(239,83,80,.12);
$third-shadow-hover:0 14px 26px -12px rgba(239,83,80,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(239,83,80,.2);

$default-shadow:0 2px 2px 0 rgba(169,169,169,.14), 0 3px 1px -2px rgba(169,169,169,.2), 0 1px 5px 0 rgba(169,169,169,.12);
$default-shadow-hover:0 14px 26px -12px rgba(169,169,169,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(169,169,169,.2);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
/*Preloader*/
.preloader{
    width: 100%;
    height: 100%;
    top:0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    .cssload-speeding-wheel{
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
}

/* formularios y tablas*/
.form-control,
.table{
    font-size:0.9rem !important;
}

.form-group{
    margin-bottom: 15px !important;
}


/* personalizacion del row*/
.row--report{
    border: 1px solid #e9e9e9;
    background: #fdfdfd;
    -webkit-print-color-adjust: exact;
    margin: 0 0 20px 0;
    border-radius: .25em;
    padding: 10px 0;
}

.row--special{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border: 1px solid #f2e1b7;
    background: #fffbf2;
    margin: 0 15px 20px 15px;
    border-radius: .25em;
    padding: 10px 0;
    width: 100%;
}

.row--report-orange{
    border: 1px solid #f2e1b7;
    background: #fffbf2;
}

/* Estilos generales */
.u-overflow-x{
    overflow-x: auto;
}

.u-block{
    display:block;
    width:100%;
}

.u-bold{
    font-weight: bold;
}

.u-pb-10{
    padding-bottom:10px;
}

.u-fw-normal{
    font-weight: 400;
}

.o-divider{
    display:block;
    border-bottom:1px solid #e9e9e9;
    margin: 0 0 10px 0;
}

.o-title-row{
    border-bottom: 1px dashed #e9e9e9;
    padding-bottom: 5px;
    margin-bottom: 13px;
}
.o-title-row--orange{
    border-bottom: 1px dashed #f2e1b7;
}


/*quitar de aqui*/
.cp-asset-sareb-report-detail-page__revision-image{
    border:1px solid red;
}

.u-page-break-before{
    page-break-before: always;
}

.u-cursor-default:hover{
    cursor:default!important;
}
.u-no-boxshadow{
    box-shadow:none !important;
}

.u-bg-light{
    background:#f8f9fa !important;
}

.u-recommendation-icon{
    position: relative;
    top: 2px;
}